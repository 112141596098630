<template>
  <b-card body>
    <div class="flex justify-between">
      <div class="d-flex w-50 align-items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black font-semibold text-2xl">
          Data Inbound
        </div>
      </div>

      <div class="flex w-50 gap-4 justify-end">
        <v-select
          v-model="partner"
          placeholder="Nama Partner"
          :options="list_partner"
          :clearable="false"
          :loading="loadingPartner"
          label="name"
          style="width: 75%;"
          :reduce="partner => partner.id"
        >
          <template
            slot="option"
            slot-scope="option"
          >
            {{ option.name }}
            <span
              v-if="option.unsubscribe === 1"
              class="ml-1 unsubscribe-tag"
            >Unsubscribe</span>
          </template>
        </v-select>
        <v-select
          v-model="status"
          placeholder="Status Inbound"
          :options="filters"
          :clearable="false"
          label="text"
          style="width: 40%;"
          :reduce="status => status.value"
        />
      </div>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <b-table
        id="table"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        class="mt-2 text-center"
        :fields="fields"
        :items="items"
        responsive
      >
        <template #cell(inbound_date)="data">
          <div>
            <div>{{ formatDate(data.item.inbound_date) }}</div>
          </div>
        </template>
        <template
          #cell(partner_name)="data"
        >
          <div class="d-flex">
            <span class="d-flex align-center">
              <b-avatar
                style="width: 40px; height: 40px;"
                rounded="circle"
                :src="data.item.image_logo_url"
              />
            </span>
            <span class="text-start ml-1">
              <div class="font-semibold">{{ data.item.partner_name }}</div>
              <div>{{ data.item.city_name }}</div>
            </span>
          </div>
        </template>
        <template #cell(shipping)="data">
          <div
            v-if="data.item.is_retur === 0"
            class="flex gap-[10px]"
          >
            <img
              v-if="data.item.shipping || data.item.shipping_type.startsWith('lainnya-')"
              src="https://storage.googleapis.com/komerce/assets/menggunakan-ekspedisi-orange.svg"
              alt="dikirim"
            >
            <img
              v-else
              src="https://storage.googleapis.com/komerce/assets/dikirim-sendiri-orange.svg"
              alt="dikirim"
            >
            <div class="text-capitalize w-36">
              <div v-if="data.item.shipping">
                <span>Ekspedisi</span>
                <br>
                <span class="text-[#626262]">({{ data.item.shipping }})</span>
              </div>
              <div v-else>
                <span>{{ data.item.shipping_type.replace('lainnya-', '') }}</span>
              </div>
              <!-- {{ data.item.shipping ? data.item.shipping : data.item.shipping_type.replace('lainnya-', '') }} -->
            </div>
          </div>
          <div
            v-else
            class="flex gap-[10px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/box-remove.svg"
              alt="retur"
            >
            <div class="w-36">Retur</div>
          </div>
        </template>
        <template #cell(no_resi)="data">
          <div v-if="data.item.no_resi === '' || data.item.no_resi === null">
            -
          </div>
          <div v-else>
            {{ data.item.no_resi }}
          </div>
        </template>
        <template #cell(status)="data">
          <div
            v-if="data.item.status === 'Proses'"
            style="color: #FBA63C"
          >
            Proses
          </div>
          <div
            v-if="data.item.status === 'Selesai'"
            style="color: #34A770"
            class="flex flex-col"
          >
            Selesai
            <span class="text-[#626262]">
              {{ SLASHED_DDMMYYYY(data.item.arrived_date) }}
            </span>
          </div>
          <div
            v-if="data.item.status === 'Dibatalkan'"
            style="color: #E31A1A"
          >
            Dibatalkan
          </div>
        </template>
        <template #cell(action)="data">
          <div
            class="underline text-capitalize"
            style="color: #08A0F7"
            type="button"
            @click="lihatDetail(data.item)"
          >
            Lihat Detail
          </div>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { INBOUND, SELECT_PARTNERS } from '@/provider/url'
import moment from 'moment'
import vSelect from 'vue-select'
import { SLASHED_DDMMYYYY } from '@/libs/filterDate'
import secureLocalStorage from '@/libs/secureLocalstorage'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      SLASHED_DDMMYYYY,
      loading: false,
      loadingPartner: false,
      items: [],

      // filter
      status: '',
      partner: '',

      // infinite scroll
      limit: 50,
      offset: 0,
      lastData: false,

      list_partner: [
        {
          id: '',
          name: 'Semua Partner',
        },
      ],
      filters: [
        {
          value: '',
          text: 'Semua Status',
        },
        {
          value: 1,
          text: 'Proses',
        },
        {
          value: 2,
          text: 'Selesai',
        },
        {
          value: 3,
          text: 'Dibatalkan',
        },
      ],
      fields: [
        {
          key: 'inbound_date', label: 'Tanggal Pengajuan', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
        {
          key: 'partner_name', label: 'Nama Partner', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
        {
          key: 'shipping', label: 'Jenis Pengiriman', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
        {
          key: 'no_resi', label: 'No. Resi', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
        {
          key: 'status', label: 'Status', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
        {
          key: 'action', label: 'Action', thClass: 'bg-white text-capitalize text-black', tdClass: 'text-black',
        },
      ],
    }
  },
  watch: {
    status() {
      this.getData()
    },
    partner() {
      this.getData()
    },
  },

  created() {
    this.getData()
    this.getPartner()
  },

  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
  },

  methods: {
    async getData() {
      this.offset = 0
      this.loading = true
      const params = `status=${this.status}&partner_id=${this.partner}&limit=${this.limit}&offset=${this.offset}`
      await provider
        .single(`${INBOUND}?${params}`)
        .then(response => {
          const { data } = response
          this.items = data
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
              timeout: 2000,
            },
          )
        })
    },

    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = `status=${this.status}&partner_id=${this.partner}&limit=${this.limit}&offset=${this.offset}`
        await provider
          .single(`${INBOUND}?${params}`)
          .then(response => {
            const { data } = response
            this.items.push(...data)
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.log(error)
          })
      }
    },

    lihatDetail(data) {
      const { id } = data
      secureLocalStorage.set('dataInbound', JSON.stringify(data))
      this.$router.push(`/data-inbound/detail/${id}`)
    },
    async getPartner() {
      this.loadingPartner = true
      await provider
        .single(SELECT_PARTNERS)
        .then(response => {
          const { data } = response
          this.list_partner.push(...data)
          this.loadingPartner = false
        })
        .catch(error => {
          this.loadingPartner = false
          console.log(error)
        })
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-menu {
  max-height: 170px;
}

.unsubscribe-tag {
  border-radius: 4px;
  border: 1px solid currentColor;
  background: transparent;

  padding: 2px 6px;
  color: currentColor
}
</style>
